<template>
  <div ref="slider" class="beer-slider" :data-beer-label="afterText">
    <img :src="after"  :alt="secondary_alt"/>
    <div class="beer-reveal" :data-beer-label="beforeText">
      <img :src="before"  :atl="primary_alt"/>
    </div>
  </div>
</template>

<script>
export default {
  props: ["before", "after", "beforeText", "afterText", "primary_alt", "secondary_alt"],
  mounted() {
    new BeerSlider(this.$refs.slider);
  }
};
</script>

<style lang="scss" scoped>
.beer-slider {
  height: 60%;
  img {
    height: 100%;
  }
}
</style>
