<template>
  <div>
    <div><button class="detail" v-on:click="open()">{{buttonTitle}}<img :src="iconSrc" aria-hidden="true"/></button></div>
  </div>
  
</template>
 
<script>
import EventBus from "../../event-bus";

export default {

  props: ["json", "buttonTitle", "iconSrc"],
  data() {
    return {
      modalOpen: false
    };
  },

  beforeMount() {

  },
  mounted() {

  },

  computed: {

  },
  methods: {
    open() {
      this.modalOpen = true;
      EventBus.$emit("vue_opendata_detail_modal_show", this.json);
    }

  },
};
</script>
<style lang="scss">

</style>