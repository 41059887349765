<template>
  <div class="MaxarTable__container">
    <div class="MaxarTable">
      <div class="tools">
        <div class="row">
          <div v-if="filters" class="filters--desktop">
            <span>Filter by:</span>
            <button v-for="(filter, index) in filtersWithData" :class="filter.filterValue === currentFilter ? 'active' : ''"
              :key="filter.filterValue + index.toString()" @click="changeCurrentFilter(filter.filterValue)">
              {{ filter.title }}
            </button>
          </div>
          <div v-if="filters" class="filters--mobile">
            <select v-model="currentFilter">
              <option v-for="(filter, index) in filtersWithData" :key="filter.filterValue + index.toString() + 'mobile'"
                :value="filter.filterValue">
                {{ filter.title }}
              </option>
            </select>
          </div>
          <div class="search">
            <div class="search-input-container">
              <input type="text" aria-label="search" v-model="search" placeholder="Search..." />
              <img src="/assets/navbar/search-3f01e1afe02a0c14b0b49d0d4bbb284c05929b46213aa0bfb38b2bad7b90e2d2.svg" aria-hidden="true"/>
            </div>
            <a aria-label="clear search" @click="clearSearch()">clear</a>
          </div>
        </div>
        <div class="row">
          <div v-if="activeSearch" class="search-feedback">
            Displaying {{this.paginatedRows.length}} of {{this.filteredRows.length}} results found ({{this.filteredLength}} total)
          </div>
          <div v-if="!activeSearch" class="search-feedback">
            Displaying {{this.paginatedRows.length}} of {{this.filteredRows.length}}
          </div>
        </div>
      </div>
      <table :data-current-filter="currentFilter">
        <thead>
          <tr>
            <th
              v-for="(column, index) in columns"
              :data-column-property="column.property"
              v-show="!column.hidden"
              :key="column.title + index.toString()"
            >
              <button @click="sort(column.property)">
                {{ column.title }}
                <div class="sortingIndicators" v-if="column.sortable">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.122"
                    height="34.393"
                    :class="
                      sortedColumn.property === column.property && sortAscending
                        ? 'active'
                        : ''
                    "
                  >
                    <path
                      data-name="Path 486"
                      d="M17.393.707l-16 16 16 17"
                      fill="none"
                      stroke-width="2"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.122"
                    height="34.393"
                    :class="
                      sortedColumn.property === column.property && !sortAscending
                        ? 'active'
                        : ''
                    "
                  >
                    <path
                      data-name="Path 486"
                      d="M17.393.707l-16 16 16 17"
                      fill="none"
                      stroke-width="2"
                    />
                  </svg>
                </div>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in paginatedRows"
            :key="'row' + index.toString()"
          >
            <td
              v-for="(column, index) in columns"
              :data-column-property="column.property"
              v-show="!column.hidden"
              :key="'column' + index.toString()"
            >
              <CellRenderer v-if="column.render_as_component" :html="'<div>'+row[column.property]+'</div>'" ></CellRenderer>
              <div v-else v-html="row[column.property]"> </div>
          </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination" v-if="pageCount > 1">
      <button
        @click="changeCurrentPage(currentPage - 1)"
        class="previous"
        aria-label="Previous page"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.122"
          height="34.393"
          v-show="currentPage !== 0"
        >
          <path
            data-name="Path 486"
            d="M17.393.707l-16 16 16 17"
            fill="none"
            stroke="#f99f1c"
            stroke-width="2"
          />
        </svg>
      </button>
      <button
        v-for="n in pageCount"
        :key="`pageButton${n}`"
        @click="changeCurrentPage(n - 1)"
        :class="n === currentPage + 1 ? 'active' : ''"
      >
        {{ styleNumberButton(n) }}
      </button>
      <button
        @click="changeCurrentPage(currentPage + 1)"
        aria-label="Next page"
        class="next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.122"
          height="34.393"
          v-show="currentPage !== pageCount - 1"
        >
          <path
            data-name="Path 486"
            d="M17.393.707l-16 16 16 17"
            fill="none"
            stroke="#f99f1c"
            stroke-width="2"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>;
  import CellRenderer from "./components/CellRenderer.vue";
export default {
  components: {
    CellRenderer
  },
  props: {
    columns: {
      type: Array,
      default: null
    },
    filters: {
      type: Array,
      default: null
    },
    rows: {
      type: Array,
      default: null
    }
  },
  computed: {
    filteredRows() {
      let filtered = this.rows;
      this.searchResultCount = 0;

      if (this.search.length) {
        // filter for rows whose column properties are searchable and whose values include the search string
        filtered = filtered.filter(item => Object.entries(item).some(entry => this.searchableColumnProperties.includes(entry[0]) && String(entry[1]).toLowerCase().includes(this.search.toLowerCase())));
        this.searchResultCount = filtered.length;
      }

      if (this.currentFilter === null) {
        return filtered;
      } else {
        return filtered.filter(item => item.filterKey === this.currentFilter);
      }
    },
    filtersWithData() {
      return this.filters.filter(f => {
        return (
          this.rows.filter(item => item.filterKey === f.filterValue).length > 0
        );
      });
    },
    filteredLength() {
      if (this.currentFilter === null) {
        return this.rows.length;
      } else {
        return this.rows.filter(item => item.filterKey === this.currentFilter).length;
      }
    },
    sortedRows() {
      const sorted = this.filteredRows.sort((a, b) => {
        // if the sorted column has a 'sort_method' of 'date' use this property for how to sort
        if (this.sortedColumn?.sort_method === "date") {
          const aDate = this.$moment(a[this.sortAttribute]);
          const bDate = this.$moment(b[this.sortAttribute]);
          return this.$moment.utc(aDate).diff(bDate);
        }
        if (a[this.sortAttribute] < b[this.sortAttribute]) {
          return -1;
        }
        if (a[this.sortAttribute] > b[this.sortAttribute]) {
          return 1;
        }
        return 0;
      });

      if (this.sortAscending) {
        return sorted;
      } else {
        return sorted.reverse();
      }
    },
    paginatedRows() {
      return this.sortedRows.slice(
        this.currentPage * this.pageLength,
        (this.currentPage + 1) * this.pageLength
      );
    },
    pageCount() {
      return Math.ceil(this.sortedRows.length / this.pageLength);
    },
    sortAttribute() {
      // if the sorted column has a 'sort_by_property' use this property for sorting, otherwise us 'property'
      return this.sortedColumn?.sort_by_property
        ? this.sortedColumn.sort_by_property
        : this.sortedColumn.property;
    },
    searchableColumnProperties() {
      // return column property names (or search by property names) where the column does not have searchable: false
      return this.columns.filter(col => col?.searchable != false).map(col => col?.search_by_property ? col?.search_by_property : col.property);
    },
    activeSearch() {
      return this.search.length ? true : false;
    }
  },
  data() {
    return {
      currentFilter: null,
      currentPage: 0,
      pageLength: 25,
      sortedColumn: this.columns[0],
      sortAscending: true,
      search: "",
      searchResultCount: 0
    };
  },
  mounted() {
    if (this.filters) {
      this.currentFilter = this.filtersWithData[0].filterValue;
    }
  },
  methods: {
    changeCurrentFilter(value) {
      this.currentFilter = value;
      this.currentPage = 0;
    },
    changeCurrentPage(value) {
      if (value > -1 && value < this.pageCount) {
        this.currentPage = value;
      }
    },
    sort(property) {
      const targetColumn = this.columns.find(
        column => column.property === property
      );

      // Check if the target column is sortable
      if (targetColumn.sortable) {
        // If the column to sort hasn't changed, just change the order
        if (this.sortedColumn.property === targetColumn.property) {
          this.sortAscending = !this.sortAscending;
        } else {
          // Otherwise, update the sorted column and set it to ascending by default
          this.sortedColumn = targetColumn;
          this.sortAscending = true;
        }
      }
    },
    styleNumberButton(number) {
      if (number < 10) {
        return `0${number}`;
      } else {
        return number;
      }
    },
    clearSearch() {
      this.search = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.MaxarTable {
  max-height: calc(100vh - 240px);
  width: 100%;
  overflow: auto;
  &__container {
    position: sticky;
    top: 100px;
    @media (max-width: 1024px) {
      top: 44px;
    }
  }
}

.tools {
  box-sizing: border-box;
  background: #999792;
  height: 80px;
  color: white;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  height: 95px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  .row {
    background: #999792;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 480px) {
      flex-direction: column;
      height: 120px;
    }

    span {
      font-family: "Blender", Arial, Helvetica, sans-serif, Calibri;
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
    }
    button {
      background: transparent;
      border: none;
      color: white;
      text-transform: uppercase;
      &:hover,
      &:focus {
        background: transparent;
        color: #fcaf17;
      }
      &.active {
        color: #fcaf17;
      }
    }
  }

    .search {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      flex-grow: 1;
      max-width: 500px;
      @media (max-width: 1024px) {
          margin-left: 20px;
        }
      @media (max-width: 480px) {
          margin-left: 0;
          margin-top: 10px;
      }

      .search-input-container {
        display: flex;
        padding: 0px 5px;
        background-color: #fff;
        flex-grow: 1;
        input {
          width: 200px;
          height: 24px;
          flex-grow: 2;
          outline: none;
          border: none;
          &:focus {
            outline: none;
          }
        }
        img {
          position: relative;
          top: 5px;
          height: 16px;
          width: 16px;
        }
      }
      a {
        margin-left: 10px;
        cursor: pointer;
      }
    }
    }

.filters--desktop {
  @media (max-width: 1024px) {
    display: none;
  }
}

.filters--mobile {
  @media (min-width: 1024px) {
    display: none;
  }
  select {
    background: transparent;
    border: none;
    color: white;
    font-family: "Blender", Arial, Helvetica, sans-serif, Calibri;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

table {
  table-layout: fixed;
  height: calc(100% - 320px);
  min-width: 100%;
  button {
    color: white;
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0;
    padding: 0;
    position: relative;
    text-decoration: underline;
    text-decoration-color: #fcaf17;
    text-decoration-thickness: 2px;
    transition: color 0.3s ease-in-out;
    text-transform: uppercase;
    &:hover {
      color: #fcaf17;
      cursor: pointer;
    }
    &:focus,
    &:active {
      outline: 1px dotted;
    }
    .sortingIndicators {
      position: absolute;
      height: 16px;
      right: -32px;
      top: 50%;
      transform: translateY(-50%);
      svg {
        position: absolute;
        width: 8px;
        right: 0;
        stroke: #ffffff;
        path {
          transform: scale(0.5) translateY(50%);
        }
      }
      svg:first-of-type {
        bottom: 0;
        transform: rotate(90deg);
      }
      svg:last-of-type {
        top: 0;
        transform: rotate(270deg);
      }
      svg.active {
        stroke: #fcaf17;
      }
    }
  }
}

table,
th,
td,
tr {
  border-collapse: collapse;
}

tr {
  position: relative;
}

th,
td {
  box-sizing: border-box;
  padding: 30px 56px;
  min-width: 225px;
  max-width: 300px;
  z-index: 1;
  @media (max-width: 1024px) {
    padding: 15px 28px;
  }
  &:first-of-type {
    position: sticky;
    left: 0;
    top: 0;
  }
}

thead {
  tr:nth-child(1) th {
    background: #434343;
    box-sizing: border-box;
    height: 100px;
    position: sticky;
    top: 95px;
    z-index: 1;
    @media (max-width: 1024px) {
      height: 50px;
    }
    @media (max-width: 480px) {
      top: 120px;
    }
    &:first-of-type {
      left: 0;
      z-index: 3;
    }
  }
}

th {
  color: white;
  text-align: left;
}

tbody {
  tr {

    @media (max-width: 480px) {
      top: 26px;
    }

    &:nth-child(odd) {
      background: #f5f5f5;
    }
    &:nth-child(even) {
      background: white;
    }
    border-bottom: 1px solid #707070;
  }

  td:first-of-type {
    background: #6b6965;
    border-bottom: 1px solid #707070;
    color: white;
    z-index: 2;
  }

}

.pagination {
  background: white;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0;
  padding: 20px;
  position: relative !important;
  padding-bottom: 10px;
  width: 100%;
  @media (max-width: 1024px) {
    padding: 10px;
  }
  button {
    background: transparent;
    box-sizing: border-box;
    border: 1px solid #707070;
    color: #707070;
    height: 40px;
    margin: 10px;
    padding: 0;
    width: 40px;
    &.active,
    &:active,
    &:focus {
      color: #fcaf17;
      border-color: #fcaf17;
    }
    &.previous,
    &.next {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.next {
      transform: rotate(180deg);
    }
  }
}
</style>
