<template>
<div class="CarouselComponent"
    v-on:touchstart.capture="handleTouchstart"
    v-on:touchend.capture="handleTouchend($event)"
    >
  <div
    class="carouselMain"  >
    <div class="images">
      <div
        class="slider"
        :style="
          `transform: translateX(-${currentSlide *
            (100 / configuration.length)}%); width: ${100 *
            configuration.length}%;`
        "
      >
        <div
          v-for="(slide, index) in configuration"
          :key="index"
          :class="
            slide.contentType === 'single_image' ? 'slide' : 'slide video'
          "
          style="background: #434343; width: 100%;"
        >
          <img
            v-if="slide.contentType === 'single_image'"
            :src="slide.image.image_value.url"
            :alt="slide.image.title"
          />
          <WistiaSlide v-else :wistia="slide.wistia" :autoplayOnScroll="slide.autoplay_on_scroll" :autoplay="slide.autoplay" :loop="slide.loop" />
        </div>
      </div>
    </div>
    <div class="copy">
      <div class="indicators">
        <div style="display: none"></div>
        <button
          v-for="(slide, index) in configuration"
          :key="slide.heading + index.toString()"
          :class="
            index === currentSlide
              ? 'active indicator navbar__link '
              : 'indicator navbar__link'
          "
          :data-index="index"
          @click="currentSlide = index"
        >
          {{ index + 1 }}
        </button>
      </div>
      <div class="slider">
        <div
          v-for="(slide, index) in configuration"
          transition="carousel"
          :key="slide.buttonLink + index"
          :class="index === currentSlide ? 'slide active' : 'slide'"
        >
          <h2>{{ slide.heading }}</h2>
          <div v-html="slide.body"></div>
          <a
            class="button"
            v-if="slide.buttonEnabled"
            :href="slide.buttonLink"
            :target="slide.buttonIsExternal ? '_blank' : '_self'"
            >{{ slide.buttonText }}</a
          >
        </div>
      </div>
      <div class="arrows">
        <button @click="decrementCurrentSlide" class="previousButton">
          Previous
        </button>
        <button @click="incrementCurrentSlide" class="nextButton">Next</button>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import WistiaSlide from "./WistiaSlide.vue";

export default {
  props: ["configuration", "images"],
  components: {
    WistiaSlide
  },
  data() {
    return {
      currentSlide: 0,
      swipePixelLength: 100,
      touchStartLocation: null
    };
  },
  computed:{
    sortedArray: function() {
      function compare(a, b) {
        if (a.order < b.order)
          return -1;
        if (a.order > b.order)
          return 1;
        return 0;
      }

      return this.configuration.sort(compare);
    }
  },
  methods: {
    incrementCurrentSlide: function() {
      if (this.currentSlide < this.configuration.length - 1) {
        this.currentSlide = this.currentSlide + 1;
      } else {
        this.currentSlide = 0;
      }
    },
    decrementCurrentSlide: function() {
      if (this.currentSlide > 0) {
        this.currentSlide = this.currentSlide - 1;
      } else {
        this.currentSlide = this.configuration.length - 1;
      }
    },
    handleTouchstart: function(event) {
      this.touchStartLocation = event.changedTouches[0].pageX;
    },
    handleTouchend: function(event) {
      const touchEndLocation = event.changedTouches[0].pageX;
      if (touchEndLocation - this.touchStartLocation > this.swipePixelLength) {
        event.preventDefault();
        this.incrementCurrentSlide();
      } else if (
        this.touchStartLocation - touchEndLocation >
        Math.abs(this.swipePixelLength)
      ) {
        event.preventDefault();
        this.decrementCurrentSlide();
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.FlexibleHorizontalCarouselComponent {
  // Overrides since the markup is slightly different from site SCSS
  .CarouselComponent {
    max-width: 1920px;
      .carouselMain {
        height: unset;
        .copy {
          .slider {
            overflow-y: unset;
            .slide {
              min-width: calc(100% - 70px);
              padding: 0 40px 20px 40px;
              order: 10;
              visibility: hidden;
              &.active {
                order: 1;
                visibility: visible;
              }
            }
            .indicators {
              padding: 20px 0 20px 40px;
            }
        }
      }
    }
  }
  

&.white{
    .sharedHeading{
      background-color: white;
      color: #2e2e2e;
      h2{
        color: #2e2e2e;
      }
    }
    .copy{
      background-color: white;
      color: #2e2e2e;
      .button{
        color: #2e2e2e;
      }
      .indicators{
        background-color: white;
      }
    }
   }
   &.dark_grey{

    .sharedHeading{
      background-color: #2e2e2e;
      color: white;
      h2{
        color: white;
      }
    }
    .copy{
      background-color: #2e2e2e;
      columns: white;
      .button{
        color: white;
      }
      .indicators{
        background-color: #2e2e2e;
      }
    }
   }
   &.light_grey{
    .sharedHeading{
      background-color: #f5f5f5;
      color: #2e2e2e;
      h2{
        color: #2e2e2e;
      }
    }
    .copy{
      background-color: #f5f5f5;
      color: #2e2e2e;
      .button{
        color: #2e2e2e;
      }
      .indicators{
        background-color: #f5f5f5;
      }
    }
   }
   &.mid_grey{
    background-color: #434343;
    .sharedHeading{
      background-color: #434343;
      color: white;
      h2{
        color: white;
      }
    }
    .copy{
      background-color: #434343;
      columns: white;
      .button{
        color: white;
      }
      .indicators{
        background-color: #434343;
      }
    }
   }
}
@media (min-width: 1024px) {
  .copy .indicators {
    position: absolute;
    bottom: 20px;
  }
  .CarouselComponent .carouselMain .copy .indicator:nth-of-type(2) {
    margin-left: 20px;
  }
}
.slide.video {
  align-items: center;
  display: flex;
}
</style>
