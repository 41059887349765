<template>
  <img :src="source" :alt="primary_alt" />
</template>

<script>
export default {
  props: ["source", "primary_alt"]
};
</script>

<style scoped lang="scss">
  img {
    width: 100%;
  }
</style>
