<template>
  <div class="slideout">
    <SingleImage v-if="slide.contentType === 'single_image'" :source="image" :alt="primary_alt" />
    <Slider
      v-if="slide.contentType === 'slider'"
      :before="image"
      :after="secondImage"
      :beforeText="slide.beforeText"
      :afterText="slide.afterText"
      :primary_alt="primary_alt"
      :secondary_alt="secondary_alt"
    />
    <Zoom
      v-if="slide.contentType === 'magnifying_glass'"
      :image1="image"
      :image2="secondImage"
      :primary_alt="primary_alt"
      :secondary_alt="secondary_alt"
    />
    <div class="copy">
      <h5>{{ slide.subheading }}</h5>
      <div v-html="slide.body"></div>
    </div>
  </div>
</template>

<script>
import SingleImage from "./SingleImage";
import Slider from "./Slider";
import Zoom from "./Zoom";
export default {
  props: ["slide", "image", "secondImage", "primary_alt", "secondary_alt"],
  components: {
    SingleImage,
    Slider,
    Zoom
  }
};
</script>

<style lang="scss" scoped>
.slideout {
  display: none;
  @media (max-width: 1024px) {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    &.active {
      max-height: 1000px;
    }
  }
}
</style>
