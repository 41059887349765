<template>
    <div>

        <a v-if="!modalOnly" @click="showModal = true" class="button">{{ triggerButtonText }}</a>

        <vue-final-modal v-model="showModal" :z-index-base="10000" classes="modal-container" :content-class="contentClass" focus-trap>
            
            <button v-if="enableClose" class="modal__close" @click="showModal = false" aria-label="close"><slot name="close-btn-img"></slot></button>
            
            <span v-if="modalHasTitleBar" class="modal__title">{{ modalTitleText }}</span>

            <div class="modal__content">
                <h2 v-if="modalHeading.length > 0">{{ modalHeading }}</h2>
                <h3 v-if="modalSubheading.length > 0">{{ modalSubheading }}</h3>
                <slot v-if="modalHasCopy" name="modal-copy"></slot>
                <a v-if="modalHasButton" :href="modalButtonLink" class="button" :target="target">{{ modalButtonText }}</a>
            </div>
            <div v-if="modalHasFooterBar" class="modal__action">
                <span v-if="modalFooterText.length > 0">{{ modalFooterText }}</span>
                <button v-if="enableClose && modalHasFooterButton" class="button" @click="showModal = false" aria-label="close">{{ modalFooterButtonText }}</button>
            </div>
        </vue-final-modal>

    </div>
</template>

<script>

import { VueFinalModal } from 'vue-final-modal'

export default {

    components: {
        VueFinalModal
    },

    name: 'ModalComponent',

    props: [
        "triggerButtonText",
        "showOnPageLoad",
        "enableClose",
        "modalHasTitleBar",
        "modalTitleText",
        "modalHeading",
        "modalSubheading",
        "modalHasCopy",
        "modalHasButton",
        "modalButtonText",
        "modalButtonLink",
        "modalButtonIsExternal",
        "modalHasFooterBar",
        "modalFooterText",
        "modalHasFooterButton",
        "modalFooterButtonText",
        "modalBackgroundColor",
        "modalOnly"
    ],

    data() {
        return {
            showModal: false
        }
    },

    mounted() {
        if(this.showOnPageLoad) {
            this.showModal = true;
        }
    },

    computed: {
        target() {
            return this.modalButtonIsExternal ? '_blank' : '_self';
        },
        contentClass() {
            return 'modal-content '+ this.modalBackgroundColor;
        }
    }

}
</script>

<style scoped lang="scss">
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    max-width: 80vw;
    margin: 0 1rem;
    padding: 1rem;
    background: #fff;
    &.dark_grey {
        background-color: #2e2e2e;
        color: white;
    }
    &.mid_grey {
        background-color:  #434343;
        color: white;
    }
    &.light_grey {
        background-color: #f5f5f5;
    }
    &.gold {
        background-color: #fcaf17;
        .modal__close > img {
            filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
        }
    }
}

.modal__title {
    margin: 0 2rem 0 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding-top: 20px;
}

.modal__action {
    display: flex;
    justify-content:  space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    border: none;
    padding: 0;
    width: 20px;
    height: 20px;
}

::v-deep .modal__close > img {
    width: 20px;
}
</style>

<style scoped lang="scss">
.dark-mode div::v-deep .modal-content {
    border-color: #2d3748;
    background-color: #1a202c;
}</style>