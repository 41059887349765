<template>
  <section class="modal" v-show="open">
    <div class="modalBox">
      <div class="loading" v-show="loading">
        <p>Loading...</p>
      </div>
      <div id="map-container">
        <div id="map-sidebar">
          <div class="browse-image" v-show="!loading">
            <div class="img-box">
              <img v-if="!loading"
                :src="'https://ard.maxar.com/api/v1/browse/preview/' + collection + '/Z' + leadingZero(properties.utm_zone) + '-' + properties.quadkey"
                :alt="'Preview of Catalog ID: ' + properties.utm_zone + ' Quadkey: ' + properties.quadkey"
                />
            </div>
            <ul>
              <li>{{ checkTilePrePost(properties.datetime) }}:
                <ul>
                  <li>Collection date: {{ convertDateWithTime(properties.datetime) }}</li>
                  <li>Catalog ID: {{ properties.catalog_id }}</li>
                  <li>Quadkey: {{ properties.quadkey }}</li>
                  <li>Satellite: {{ properties.platform }}</li>
                </ul>
              </li>
              <li>Assets:
                <ul>
                  <li v-for="link in assetLinks">
                    <a :href="rootAddress + assetsPath + link.href.slice(2)" target="_blank">{{ link.title }}</a>
                  </li>
                  <li><a :href="rootAddress + '/' + json" target="_blank">JSON</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div id="map"></div>
      </div>
      <button @click="closeModal()" class="close remove" aria-label="close">×</button>
    </div>
  </section>
</template>
<script>
import EventBus from "../../event-bus";
export default {
  data() {
    return {
      open: false,
      json: "",
      loading: true,
      rootAddress: "https://maxar-opendata.s3.us-west-2.amazonaws.com",
      properties: {},
      assets: {},
      collection: ""
    };
  },
  computed: {
    assetsPath() {
      let path_arr = this.json.split("/");
      path_arr.pop()
      return "/" + path_arr.join("/") + "/";
    },

    assetLinks() {
      return Object.entries(this.assets).map(entry => entry[1]);
    }
  },
  created() {
    // Sets up the Event Bus listener using 
    // the custom event name and assosciates
    // it with a component method.
    EventBus.$on("vue_opendata_detail_modal_show", (json) => {
      this.json = json;
      this.loading = true;
      this.open = true;
      this.init();
    });
  },
  destroyed() {
    // Removes Event Bus listener upon removal
    // of template from DOM.
    EventBus.$off("vue_opendata_detail_modal_show", this.open = false);
  },
  mounted() {

    const mapboxgl = require("!mapbox-gl");

    this.map = new mapboxgl.Map({
      accessToken:
        "pk.eyJ1IjoiZGdpbnRlcmFjdGl2ZSIsImEiOiJKSGczdWhnIn0.UyoVWvXEQ6KO_xx1Ry2lQA",
      container: "map",
      style: "mapbox://styles/mapbox/light-v10",
      cooperativeGestures: true,
      center: [2.5161, 37.138],
      zoom: 1.5,
      pitchWithRotate: false,
      dragRotate: false,
      touchZoomRotate: false,
    });

    this.map.on('load', function () {
      if (this.open) {
        this.map.resize();
      }
    });

  },
  methods: {
    checkTilePrePost(date) {
      if (this.$moment(date).isBefore(this.eventdate)) {
        return "Pre event";
      } else {
        return "Post event";
      }
    },
    leadingZero(value) {
      let num = parseInt(value);
      if (num < 10) {
        return "0" + num;
      } else {
        return num;
      }
    },
    convertDateWithTime(dt) {
      return this.$moment(dt).format("MMMM Do YYYY, hh:mm:ssZ");
    },
    closeModal() {
      this.open = false;
    },
    init() {
      const mapboxgl = require("!mapbox-gl");

      this.map.resize();

      if (this.map.getLayer("prefill")) {
        this.map.removeLayer('prefill');
      }
      if (this.map.getLayer("pretileOutline")) {
        this.map.removeLayer('pretileOutline');
      }
      if (this.map.getSource("pretiles")) {
        this.map.removeSource('pretiles');
      }

      this.$http.get(this.rootAddress + "/" + this.json).then((response) => {

        this.properties = response.data.properties;
        this.assets = response.data.assets;
        this.collection = response.data.collection;

        this.map.addSource(`pretiles`, {
          type: "geojson",
          data: response.data,
        });

        this.map.addLayer({
          id: `prefill`,
          type: "fill",
          source: `pretiles`,
          paint: {
            "fill-color": "#888888",
            "fill-opacity": 0.4,
          },
          filter: ["==", "$type", "Polygon"],
        });

        var outlineLayer = this.map.addLayer({
          id: `pretileOutline`,
          type: "line",
          source: `pretiles`,
          paint: {
            "line-color": "#4164FB",
            "line-opacity": 0.7,
            "line-width": 3,
          },
          filter: ["==", "$type", "Polygon"],
        });

        this.map.resize();

        const corner1 = new mapboxgl.LngLat(response.data.bbox[0], response.data.bbox[1]);
        const corner2 = new mapboxgl.LngLat(response.data.bbox[2], response.data.bbox[3]);

        const boundingBox = new mapboxgl.LngLatBounds(corner1, corner2);

        this.map.fitBounds(boundingBox, { padding: 20 });

        this.loading = false;

      });
    }
  },
};
</script>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(#333231, 0.7);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  .modalBox {
    z-index: 21;
    height: fit-content;
    background-color: white;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 40px;
    max-height: 80%;

    @media (max-width: 1025px) {
      max-width: 100%;
      height: auto;
      height: fit-content;
    }

    .remove {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 8px 8px;
      line-height: 0.5;
    }
  }

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
    display: flex;
    align-items: center;

    p {
      text-align: center;
      margin: 0 auto;
    }
  }

}

#map-container {
  width: 75vw;
  height: 75vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #707070;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fcaf17;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #fcaf17;
  }

  @media (max-width: 1025px) {
    flex-direction: column-reverse;
    overflow: auto;
  }



  #map {
    flex-grow: 1;

    @media (max-width: 1025px) {
      flex-grow: 0;
    }
  }

  #map-sidebar {

    min-width: 350px;

    @media (max-width: 1025px) {
      min-width: unset;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #707070;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #fcaf17;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #fcaf17;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    background-color: rgba(lightgray, 0.3);

    @media (max-width: 1025px) {
      overflow: visible;
    }

    .browse-image {
      padding: 0 20px 20px 20px;
      margin-top: 20px;
      width: 100%;
      box-sizing: border-box;

      @media (max-width: 1025px) {
        flex-direction: row;
      }

      .img-box {
        margin-bottom: 20px;
        aspect-ratio: auto 1 / 1;
        background-color: #000;

      display: flex;
      align-items: center;

        img {
          width: 100%;
          height: auto;
        }
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          border-bottom: 1px solid black;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;
          margin-bottom: 10px;
          font-weight: 600;

          ul {
            text-align: left;
            margin-top: 5px;

            li {
              border-bottom: none;
              padding-block: 0;
              display: block;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
</style>
