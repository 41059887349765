<template>
  <div class="container">
    <div class="formFields">
      <div
        class="slideFields"
        v-for="(slide, index) in newConfiguration.slides"
        :key="index"
      >
        <h4 style="display: inline-block;">Slide {{ index + 1 }}</h4>
        <button @click="removeSlide(index)" style="display: inline-block">
          Remove slide
        </button>
        <label>Slide {{ index + 1 }} heading</label>
        <input v-model="slide.heading" placeholder="Slide heading" />
        <label>Slide {{ index + 1 }} subheading</label>
        <input v-model="slide.subheading" placeholder="Slide subheading" />
        <label>Slide {{ index + 1 }} body</label>
        <ckeditor v-model="slide.body"></ckeditor>
        <label>Slide {{ index + 1 }} type</label>
        <select v-model="slide.contentType">
          <option disabled value>Please select one</option>
          <option value="single_image">Single image</option>
          <option value="slider">Slider</option>
          <option value="magnifying_glass">Magnifying glass</option>
        </select>
        <div v-if="slide.contentType === 'slider'">
          <label>Before label</label>
          <input v-model="slide.beforeText" placeholder="Before label" />
          <label>After label</label>
          <input v-model="slide.afterText" placeholder="After label" />
        </div>
        <div v-if="index === 0">
          <label>Slide {{ index + 1 }} primary image</label>
          <slot name="slide_1_image"></slot>
          <label v-if="slide.contentType !== 'single_image'"
            >Slide {{ index + 1 }} secondary image</label
          >
          <slot
            v-if="slide.contentType !== 'single_image'"
            name="slide_1_secondary_image"
          ></slot>
        </div>
        <div v-else-if="index === 1">
          <label>Slide {{ index + 1 }} primary image</label>
          <slot name="slide_2_image"></slot>
          <label v-if="slide.contentType !== 'single_image'"
            >Slide {{ index + 1 }} secondary image</label
          >
          <slot
            v-if="slide.contentType !== 'single_image'"
            name="slide_2_secondary_image"
          ></slot>
        </div>
        <div v-else-if="index === 2">
          <label>Slide {{ index + 1 }} primary image</label>
          <slot name="slide_3_image"></slot>
          <label v-if="slide.contentType !== 'single_image'"
            >Slide {{ index + 1 }} secondary image</label
          >
          <slot
            v-if="slide.contentType !== 'single_image'"
            name="slide_3_secondary_image"
          ></slot>
        </div>
        <div v-else-if="index === 3">
          <label>Slide {{ index + 1 }} primary image</label>
          <slot name="slide_4_image"></slot>
          <label v-if="slide.contentType !== 'single_image'"
            >Slide {{ index + 1 }} secondary image</label
          >
          <slot
            v-if="slide.contentType !== 'single_image'"
            name="slide_4_secondary_image"
          ></slot>
        </div>
        <div v-else-if="index === 4">
          <label>Slide {{ index + 1 }} primary image</label>
          <slot name="slide_5_image"></slot>
          <label v-if="slide.contentType !== 'single_image'"
            >Slide {{ index + 1 }} secondary image</label
          >
          <slot
            v-if="slide.contentType !== 'single_image'"
            name="slide_5_secondary_image"
          ></slot>
        </div>
      </div>
    </div>
    <button @click="addSlide" style="margin-bottom: 1em">Add slide</button>
    <input
      style="width: 100%"
      type="hidden"
      :id="`page_carousel_components_attributes_${childindex}_configuration`"
      :name="
        `page[vertical_carousel_components_attributes][${childindex}][configuration]`
      "
      :value="JSON.stringify(newConfiguration)"
    />
  </div>
</template>

<script>
import CKEditor from "ckeditor4-vue";
export default {
  props: ["childindex", "configuration"],
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      newConfiguration: null
    };
  },
  created() {
    if (this.configuration === null) {
      const blankConfig = { slides: [] };
      this.newConfiguration = blankConfig;
    } else {
      this.newConfiguration = JSON.parse(this.configuration);
    }
  },
  methods: {
    addSlide() {
      event.preventDefault();
      const slide = {
        heading: "",
        subheading: "",
        body: "",
        contentType: "single_image"
      };
      if (this.newConfiguration.slides.length < 5) {
        this.newConfiguration.slides.push(slide);
      }
    },
    removeSlide(index) {
      event.preventDefault();
      this.newConfiguration.slides.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.formFields {
  display: flex;
  flex-wrap: wrap;
}
.slideFields {
  margin: 12px;
  max-width: 400px;
}
</style>
