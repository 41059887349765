<template>
    <div class="blog_feed_container">
        <article v-if="entries.length" v-for="entry in entries" class="blog_entry">
            <a :href="entry.url" target="_blank">
                <img :src="entry.featuredImage[0].sized" />
                <div class="blog_entry_copy">
                    <h4>{{ entry.title }}</h4>
                    <p>{{ entry.postDate }}</p>
                </div>
            </a>
        </article>
        <p v-if="entries.length == 0 && !this.error">Loading</p>
        <p v-if="this.error"><a :href="url+categorySlug" target="_blank" class="button">Learn more on our blog</a></p>
    </div>
</template>

<script>

import axios from 'axios'

export default {

    name: 'BlogFeed',

    props: ["url", "endpoint", "count", "categoryId", "categorySlug"],
    computed: {

    },
    data() {
        return {
            entries: [],
            error: false
        };
    },
    mounted() {
       this.getEntries()
    },
    methods: {
        async getEntries() {

            let categoryFilter = this.categoryId == -1 ? '' : `, relatedToCategories: [{id: "${this.categoryId}"}]`

            try {
                const res = await axios.post(
                    this.url+this.endpoint, {
                    query: `
                        {
                            entries(section: "blog", limit: ${this.count} orderBy: "postDate DESC" ${categoryFilter}) {
                                title
                                url
                                ... on blog_blog_Entry {
                                postDate @formatDateTime(format: "mm d, Y")
                                    featuredImage {
                                        filename
                                        url
                                        sized: url @transform(width: 500, height: 500)
                                    }
                                }
                            }
                        }
                    `
                })
                this.entries = res.data.data.entries;
            } catch (e) {
                console.log('err', e)
                this.error = true;
            }
        }

    }
};
</script>


<style lang="scss" scoped></style>