<template>
  <div class="container">
    <div class="formFields">
      <div
        v-for="(card, index) in newConfiguration.cards"
        :key="index"
        style="margin-bottom: 20px"
      >
        <CardFields :card="card" :index="index">
          <template v-slot:imageUpload>
            <slot :name="`card_${index + 1}_image`"></slot>
          </template>
          <template v-slot:secondaryImageUpload>
            <slot :name="`card_${index + 1}_secondary_image`"></slot>
          </template>
        </CardFields>
        <button @click="removeCard(index)">Remove card</button>
      </div>
    </div>
    <button @click="addCard">Add card</button>
    <input
      style="width: 100%"
      type="hidden"
      :id="`page_three_card_components_attributes_${childindex}_configuration`"
      :name="
        `page[three_card_components_attributes][${childindex}][configuration]`
      "
      :value="JSON.stringify(newConfiguration)"
    />
  </div>
</template>

<script>
import CardFields from "./CardFields";

export default {
  props: ["childindex", "configuration"],
  components: {
    CardFields
  },
  data() {
    return {
      newConfiguration: null
    };
  },
  computed:{
    sortedArray: function() {
      function compare(a, b) {
        if (a.order < b.order)
          return -1;
        if (a.order > b.order)
          return 1;
        return 0;
      }

      return this.newConfiguration.cards.sort(compare);
    }
  },
  created() {
    if (this.configuration === null) {
      const blankConfig = { cards: [{order: 1}, {order: 2}, {order: 3}] };
      this.newConfiguration = blankConfig;
    } else {
      this.newConfiguration = JSON.parse(this.configuration);
    }
  },
  methods: {
    addCard(event) {
      event.preventDefault();
      if (this.newConfiguration.cards.length < 10) {
        this.newConfiguration.cards.push({});
      }
    },
    removeCard(index) {
      event.preventDefault();
      const remove = confirm(
        "Are you sure you want to remove this card? This cannot be undone."
      );
      if (remove) {
        this.newConfiguration.cards.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.formFields {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
</style>
