/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import axios from 'axios';
import MaxarTable from "../components/MaxarTable/MaxarTable.vue";
import VueOpendataDetailButton from "../components/OpenData/VueOpendataDetailButton.vue";
import VueOpendataDetailModal from "../components/OpenData/VueOpendataDetailModal.vue";
import FlexibleHorizontalCarousel from "../components/FlexibleHorizontalCarousel/index.vue";
import FlexibleHorizontalCarouselForm from "../components/FlexibleHorizontalCarousel/Form.vue";
import VerticalCarousel from "../components/VerticalCarousel.vue";
import VerticalCarouselForm from "../components/VerticalCarousel/Form.vue";
import ThreeCardComponentForm from "../components/ThreeCardComponent/Form.vue";
import BlogFeed from "../components/BlogFeed/BlogFeed.vue";
import BlogFeedCategoriesSelect from "../components/BlogFeed/BlogFeedCategoriesSelect.vue";
import ModalComponent from "../components/ModalComponent.vue";

Vue.prototype.$http = axios;
Vue.use(require('vue-moment')) ;

document.addEventListener("DOMContentLoaded", () => {
  const targets = document.querySelectorAll('[data-vue-target="true"]');
  for (const target of targets) {
    const vue = new Vue({
      el: target,
      components: {
        VueOpendataDetailButton,
        VueOpendataDetailModal,
        FlexibleHorizontalCarousel,
        FlexibleHorizontalCarouselForm,
        MaxarTable,
        FlexibleHorizontalCarousel,
        ThreeCardComponentForm,
        VerticalCarousel,
        VerticalCarouselForm,
        BlogFeed,
        BlogFeedCategoriesSelect,
        ModalComponent
      }
    }).$mount();
  }
});

document.addEventListener("NewVueComponent", event => {
  new Vue({
    el: event.target,
    components: {
      FlexibleHorizontalCarouselForm,
      ThreeCardComponentForm,
      VerticalCarousel,
      VerticalCarouselForm,
      BlogFeedCategoriesSelect
    }
  }).$mount();
});
