<template>
  <div class="CarouselComponent__video">
    <script
      :src="`https://fast.wistia.com/embed/medias/${wistia}.jsonp`"
      type="application/javascript"
      async
    ></script>

    <script
      src="https://fast.wistia.com/assets/external/E-v1.js"
      type="application/javascript"
      async
    ></script>

    <div
      class="wistia_responsive_padding"
      style="padding:56.25% 0 0 0;position:relative;"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height:100%;left:0;position:absolute;top:0;width:100%;"
      >
        <div
          class="wistia_embed videoFoam=true"
          v-bind="autoplayOnScrollAttributes"
          :class="[
            `wistia_async_${wistia}`,
            autoplayOnScroll ? 'playbar=false playPauseNotifier=false controlsVisibleOnLoad=false playSuspendedOffScreen=true silentAutoPlay=true muted=true' : '',
            autoplay && !autoplayOnScroll ? 'autoPlay=true muted=true silentAutoPlay=true' : '',
            loop ? 'endVideoBehavior=loop' : ''
          ]"
          style="height:100%;position:relative;width:100%"
        >
          <div
            class="wistia_swatch"
            style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
          >
            <img
              :src="`https://fast.wistia.com/embed/medias/${wistia}/swatch`"
              style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
              alt
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wistia: {
      type: String,
      default: null
    },
    autoplayOnScroll: {
      type: Boolean,
      default: null
    },
    autoplay: {
      type: Boolean,
      default: null
    },
    loop: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    autoplayOnScrollAttributes() {
      if(this.autoplayOnScroll) {
        return {
          'data-aos': 'auto-play-in',
          'data-aos-id': 'auto-play-on-scroll-wistia'
        }
      } 
    }
  }
};
</script>

<style lang="scss">
.CarouselComponent__video {
  min-width: calc(100vw - 48px);
  @media (min-width: 1024px) {
    min-width: 50vw;
  }
}
</style>
