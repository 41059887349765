<template>
  <div class="container">
    <aside>
      <div
        class="indicator"
        :style="
          `height: calc(${percent}% - 1px); top: calc(${currentSlide *
            percent}% + 1px)`
        "
      ></div>
      <div
        v-for="(slide, index) in activeSlides"
        @click="updateCurrentSlide(index)"
        :key="slide.heading"
        :class="
          `tab ${index === activeSlides.length - 1 ? 'no-border' : ''} ${
            currentSlide === index ? 'active' : ''
          }`
        "
        :style="`height: ${percent}%; max-height: ${percent}%;`"
      >
        <div>
          <span>{{ `0${index + 1}` }}</span>
          <h4>{{ slide.heading }}</h4>
          <Slideout
            :class="currentSlide === index ? 'active' : ''"
            :slide="slide"
            :image="images[index].primary_image"
            :secondImage="images[index].secondary_image"
            :primary_alt="images[index].primary_alt"
            :secondary_alt="images[index].secondary_alt"
          />
        </div>
      </div>
    </aside>
    <div style="flex: 1">
      <Featured
        v-for="(slide, index) in activeSlides"
        :key="slide.heading + index"
        :slide="slide"
        :image="images[index].primary_image"
        :secondImage="images[index].secondary_image"
        :primary_alt="images[index].primary_alt"
        :secondary_alt="images[index].secondary_alt"
        :number="index"
        :active="featuredActive === index"
        v-show="currentSlide === index"
      />
    </div>
  </div>
</template>

<script>
import Featured from "./VerticalCarousel/Featured";
import Slideout from "./VerticalCarousel/Slideout";
export default {
  components: {
    Featured,
    Slideout
  },
  props: ["configuration", "images"],
  computed: {
    activeSlides: function() {
      return this.configuration.slides;
    },
    percent: function() {
      return Math.floor(100 / this.activeSlides.length);
    }
  },
  data() {
    return {
      currentSlide: 0,
      featuredActive: 0
    };
  },
  methods: {
    updateCurrentSlide(index) {
      this.featuredActive = null;
      setTimeout(() => {
        this.currentSlide = index;
      }, 300);
      setTimeout(() => {
        this.featuredActive = index;
      }, 400);
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  background: #4c4b48;
  display: flex;
  height: 80vh;
  overflow: hidden;
  position: relative;
  @media (max-width: 1024px) {
    height: unset;
    min-height: 80vh;
  }
}
aside {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
  position: relative;
  @media (max-width: 1024px) {
    width: 100%;
  }
  .indicator {
    position: absolute;
    left: 1px;
    width: 1em;
    background-color: #fcaf17;
    transition: top 0.4s ease;
    z-index: 2;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .tab {
    background: white;
    box-sizing: border-box;
    border: 1px solid #707070;
    border-bottom: none;
    color: black;
    padding: 1em;
    position: relative;
    transition: max-height 0.3s ease-in-out;
    &.active {
      @media (max-width: 1024px) {
        height: unset !important;
      }
    }
    &.no-border {
      border-bottom: 1px solid #707070;
    }
    & > div {
      padding-left: 20px;
      @media (max-width: 1024px) {
        padding-left: 0;
      }
    }
  }
}
h4,
h5 {
  margin-top: 20px;
}
span {
  font-family: "Blender", Arial, Helvetica, sans-serif, Calibri;
  font-weight: 300;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
</style>
