<template>
  <div class="container">
    <div class="formFields">
      <SlideFields
        v-for="(slide, index) in newConfiguration.slides"
        :key="index"
        :index="index"
        :slide="slide"
      >
        <template v-slot:removeButton>
          <button @click="removeSlide(index)" style="display: inline-block">
            Remove slide
          </button>
        </template>
        <template v-slot:imageUpload>
          <slot :name="`slide_${index + 1}_image`"></slot>
        </template>
      </SlideFields>
    </div>
    <button @click="addSlide" style="margin-bottom: 1em">Add slide</button>
    <input
      style="width: 100%"
      :id="
        `page_flexible_horizontal_carousel_components_attributes_${childindex}_configuration`
      "
      hidden="true"
      :name="
        `page[flexible_horizontal_carousel_components_attributes][${childindex}][configuration]`
      "
      :value="JSON.stringify(newConfiguration)"
    />
  </div>
</template>

<script>
import SlideFields from "./SlideFields";

export default {
  props: ["childindex", "configuration"],
  components: {
    SlideFields
  },
  data() {
    return {
      newConfiguration: null
    };
  },
  computed:{
    sortedArray: function() {
      function compare(a, b) {
        if (a.order < b.order)
          return -1;
        if (a.order > b.order)
          return 1;
        return 0;
      }

      return this.newConfiguration.slides.sort(compare);
    }
  },
  created() {
    if (this.configuration === null) {
      const blankConfig = { slides: [], deleted_images: []};
      this.newConfiguration = blankConfig;
    } else {
      this.newConfiguration = JSON.parse(this.configuration);
    }
  },
  methods: {
    addSlide() {
      event.preventDefault();
      const slide = {
        heading: "",
        subheading: "",
        body: "",
        buttonEnabled: false,
        buttonText: "",
        buttonLink: "",
        buttonIsExternal: false,
        contentType: "single_image",
        order: this.newConfiguration.slides.length + 1
      };
      if (this.newConfiguration.slides.length < 10) {
        this.newConfiguration.slides.push(slide);
      }
    },
    removeSlide(index) {
      event.preventDefault();
      // So right here I need to expand on splice, or write my own method, 
      // that not only removes the slide from config, 
      // but appropriately addresses the associate images.
      // since all this does is edit the json, can also make a note of which slide was just deleted
      // probably need to add some unique identifier to slides at generation for use when deleting
      // and correlating deleted slide with needing-to-be-deleted image
      // can I add the actual Image.ID to find and dlete that way?
      // either in slide fields or this form need to do the above
      // in _fields can i make `<template v-slot:slide_1_image>` dynamic via slide/config
      // instead of hard coded slide reference?
      // 
      // also need to remove/swap image after clicking remove, the sub-component is not updating
      var theId = "special-unique-" + (index + 1)
      console.log(theId);
      var deletedImage = document.getElementById(theId).innerHTML
      this.newConfiguration.deleted_images.push(deletedImage)
      this.newConfiguration.slides.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.formFields {
  display: flex;
  flex-wrap: wrap;
}
</style>
