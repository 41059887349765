<template>
    <div class="blog_categories_select_container">
        <select v-if="options.length" v-model="category_id" required>
            <option v-for="option in options" :value="option.category_id">
                {{ option.title }}
            </option>
        </select>
        <p v-if="options.length == 0 && !this.error" class="loading">Loading</p>
        <p v-if="error" class="error">Error loading blog categories.</p>
        <slot name="id" v-bind:value="category_id">{{ category_id }}</slot>
        <slot name="slug" v-bind:value="category_slug">{{ category_slug }}</slot>
    </div>

</template>

<script>

import axios from 'axios'

export default {
    name: 'BlogFeedCategoriesSelect',
    props: ["endpoint", "cat_id", "cat_slug"],
    data() {
        return {
            category_id: this.cat_id,
            options: [],
            error: false
        };
    },
    computed: {
        category_slug() {
            return this.options.filter((option) => option.category_id === this.category_id).map(option => option.category_slug)[0];
        }
    },
    created() {
        this.getCategories()
    },
    methods: {
        async getCategories() {
            try {
                const res = await axios.post(
                    this.endpoint, {
                    query: `
                    {
                        categories {
                            title
                            category_id: id
                            category_slug: slug
                        }
                    }
                    `
                })
                this.options = [{title: 'All', category_id: -1, category_slug: ''}, ...res.data.data.categories]
            } catch (e) {
                console.log('err', e);
                this.error = true;
            }
        }

    }
};
</script>


<style lang="scss" scoped></style>