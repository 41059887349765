<template>
  <fieldset>
    <h4 style="display: inline-block;">Slide {{ index + 1 }}</h4>
    <slot name="removeButton"></slot>
    <label>Slide Order</label>
    <!-- need to grab the carousel, grab associated image for the slide, and hand it over to here to get Image.ID associated with this slide,
    add a new attribute to params for deleted slides/images, and run a method to handle the deletion.
    image ID needs to be attached to the slide at generation and immutable for purposes of deleting multiple non-sequential slides in one go -->
    <input v-model.number="slide.order" type="number" placeholder="10" max="10" min="1" />
    <label>Slide {{ index + 1 }} heading</label>
    <input v-model="slide.heading" placeholder="Slide heading" />
    <label>Slide {{ index + 1 }} body</label>
    <ckeditor v-model="slide.body"></ckeditor>
    <label>Slide {{ index + 1 }} button enabled</label>
    <input type="checkbox" v-model="slide.buttonEnabled" />
    <label>Slide {{ index + 1 }} button text</label>
    <input v-model="slide.buttonText" />
    <label>Slide {{ index + 1 }} button link</label>
    <input v-model="slide.buttonLink" />
    <label>Slide {{ index + 1 }} button is external</label>
    <input type="checkbox" v-model="slide.buttonIsExternal" />
    <label>Slide {{ index + 1 }} type</label>
    <select v-model="slide.contentType">
      <option disabled value>Please select one</option>
      <option value="single_image">Image</option>
      <option value="video">Video</option>
    </select>
    <div v-if="slide.contentType === 'single_image'">
      <label>Slide {{ index + 1 }} image</label>
      <slot name="imageUpload"></slot>
    </div>
    <div v-else>
      <label>Slide {{ index + 1 }} Wistia slug</label>
      <input v-model="slide.wistia" />
      <label>Autoplay on scroll</label>
      <input type="checkbox" v-model="slide.autoplay_on_scroll" />
      <label>Autoplay</label>
      <input type="checkbox" v-model="slide.autoplay" />
      <label>Loop</label>
      <input type="checkbox" v-model="slide.loop" />
    </div>
  </fieldset>
</template>

<script>
import CKEditor from "ckeditor4-vue";
export default {
  props: {
    index: {
      type: Number,
      default: null
    },
    slide: {
      type: Object,
      default: null
    }
  },
  components: {
    ckeditor: CKEditor.component
  }
};
</script>

<style lang="scss" scoped>
fieldset {
  margin: 12px;
  max-width: 400px;
}
</style>
