<template>
  <fieldset>
    <label>Card {{ index + 1 }} heading</label>
    <input v-model="card.heading" />
    <label>Card {{ index + 1 }} copy</label>
    <ckeditor v-model="card.body"></ckeditor>
    <label>Card {{ index + 1 }} button enabled</label>
    <input type="checkbox" v-model="card.buttonEnabled" />
    <label>Card {{ index + 1 }} button text</label>
    <input v-model="card.buttonText" />
    <label>Card {{ index + 1 }} button link</label>
    <input v-model="card.buttonLink" />
    <label>Card {{ index + 1 }} button is external</label>
    <input type="checkbox" v-model="card.buttonIsExternal" />
    <label>Card {{ index + 1 }} inverted</label>
    <input type="checkbox" v-model="card.inverted" />
    <label>Card type</label>
    <select v-model="card.contentType">
      <option value="single_image">Image</option>
      <option value="video">Video</option>
      <option value="magnifier">Magnifying loop</option>
    </select>
    <div v-if="card.contentType === 'single_image'">
      <slot name="imageUpload"></slot>
    </div>
    <div v-else-if="card.contentType === 'video'">
      <label>Card {{ index + 1 }}</label>
      <label>Wistia id</label>
      <input v-model="card.wistia" />
      <label>Autoplay on scroll</label>
      <input type="checkbox" v-model="card.autoplay_on_scroll" />
      <label>Autoplay</label>
      <input type="checkbox" v-model="card.autoplay" />
      <label>Loop</label>
      <input type="checkbox" v-model="card.loop" />
    </div>
    <div v-else-if="card.contentType === 'magnifier'">
      <slot name="imageUpload"></slot>
      <slot name="secondaryImageUpload"></slot>
    </div>
  </fieldset>
</template>

<script>
import CKEditor from "ckeditor4-vue";
export default {
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    card: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  }
};
</script>
