<template>
  <img :src="image1" :data-zoom="image2" ref="zoom" :alt="primary_alt" :data-zoom-alt="secondary_alt" />
</template>

<script>
import Drift from "drift-zoom";
export default {
  props: {
    image1: String,
    image2: String,
    primary_alt: String,
    secondary_alt: String
  },
  mounted() {
    new Drift(this.$refs.zoom, {
      containInline: false,
      inlineContainer: document.body,
      inlinePane: true,
      showWhitespaceAtEdges: false,
      hoverBoundingBox: false
    });
  }
};
</script>
