<template>
  <div class="featured">
    <img v-if="slide.contentType === 'single_image'" :src="image" :alt="primary_alt"/>
    <Slider
      v-if="slide.contentType === 'slider'"
      :before="image"
      :after="secondImage"
      :beforeText="slide.beforeText"
      :afterText="slide.afterText"
      :primary_alt="primary_alt"
      :secondary_alt="secondary_alt"
    />
    <Zoom
      v-if="slide.contentType === 'magnifying_glass'"
      :image1="image"
      :image2="secondImage"
      :primary_alt="primary_alt"
      :secondary_alt="secondary_alt"
    />
    <div class="copy">
      <span>{{ `0${number + 1}` }}</span>
      <h5>{{ slide.subheading }}</h5>
      <div v-html="slide.body"></div>
    </div>
  </div>
</template>

<script>
import Slider from "./Slider";
import Zoom from "./Zoom";

export default {
  props: ["active", "slide", "number", "image", "secondImage", "primary_alt", "secondary_alt"],
  components: {
    Slider,
    Zoom
  }
};
</script>

<style lang="scss" scoped>
.featured {
  background: #333231;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
  img {
    height: 60%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 1;
  }
  .copy {
    background: #333231;
    box-sizing: border-box;
    color: white;
    flex: 1;
    overflow: auto;
    padding: 40px 60px;
    @media (max-width: 1024px) {
      padding: 20px 30px;
    }
    & > * {
      max-width: 500px;
    }
  }
}
h4,
h5 {
  margin-top: 20px;
}
span {
  font-family: "Blender", Arial, Helvetica, sans-serif, Calibri;
  font-weight: 300;
}
</style>
